.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 80vh;
}

@media screen and (max-width: 767px) {
  .cta {
    display: flex;
    flex-direction: column;
  }

  .cta_img {
    border-radius: 5px;
  }

  .center-content {
    flex-direction: row;
  }
}

/* Carousel */
.fade-in {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.progress-bar-container {
  width: 10%;
  height: 4px;
  margin-top: 10px;
}

.progress-bar {
  height: 100%;
  background-color: #007bff;
}
/* Carousel */

/* homeCarousel */
.carousel-caption{
  line-height: .2em;
  margin-top: 20%;
}

.carousel-caption h3{
  font-size: calc(.7em + .5vw);
}

.carousel-caption p{
  font-size: calc(.5em + .4vw);
}
/* homeCarousel */
