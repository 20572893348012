.download_Resource{
    padding: .5em 1em;
    border-radius: 10px;
    text-decoration: none;
    font-weight: 700;
    color: black !important;
    background-color: gray;
}

.download_Resource:hover{
    background-color: goldenrod;
    color: aliceblue;
}

/* blogcard */
.card-container {
    height: 400px;
  }
  
  .card-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .card-content {
    height: 100%;
  }
  
/* blogcard */