.about-title {
  border-left: solid 6px maroon;
  font-weight: 700;
}

.about_content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.report_downloads {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1em;
}

/* download button */
#downloader {
  border: none;
  display: flex;
  padding: 0.75rem 1.5rem;
  background-color: #488aec;
  color: #ffffff;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  align-items: center;
  border-radius: 0.5rem;
  user-select: none;
  gap: 0.75rem;
  box-shadow: 0 4px 6px -1px #488aec31, 0 2px 4px -1px #488aec17;
  transition: all 0.6s ease;
  cursor: pointer;
}

#downloader:hover {
  box-shadow: 0 10px 15px -3px #488aec4f, 0 4px 6px -2px #488aec17;
  background-color: #2d2d2d;
}

#downloader:focus,
#downloader:active {
  opacity: 0.85;
  box-shadow: none;
}

#downloader svg {
  width: 1.25rem;
  height: 1.25rem;
}
/* download button */

/* member card */
.members {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px 10px;
  grid-template-areas:
    ". . ."
    ". . .";
}

#member_Card {
  width: calc(13em + 0.5vw);
}

/* #member_Card img{
  width: calc(50px + 10vw);
  height: calc(50px + 10vw);
} */
