.complaints_rules{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

::placeholder{
    font-weight: 500;
    color: grey !important;
}