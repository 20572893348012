.hoverDropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2d2d2d;
}

.dropbtn {
  background-color: #2d2d2d;
  color: white;
  padding: 10px;
  font-size: 1em;
  display: flex;
  flex-direction: column;
  gap: .3em;
  align-items: center;
  text-align: center;
  cursor: pointer;
  width: 32.9vw;
}

.drpdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fefefef3;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 100%;
}

.dropdown-content a {
  color: black;
  text-decoration: none;
  display: block;
  height: 100%;
  margin: 5px 10px;
  font-size: calc(10px + 0.3vw) !important;
}

.dropdown-content a:hover {
  color: goldenrod;
  font-weight: 700;
}

.dropdown:hover .dropdown-content {
  display: block;
}
