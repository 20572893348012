body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

/* prestyle */
.text-img{
  font-size: calc(8px + 0.3vw) !important;
}

/* navigation bar */
#logo_container{
  display: flex;
  flex-direction: column;
  width: calc(20em + 1vw);
  line-height: 1.5em;
  margin-top: .8em;
}

.logo{
  width: calc(20em + 1vw);
}

#logo{
  width: calc(13em + 1vw);
  height: auto;
}

/* navigation bar */

.text-divide{
  font-size: calc(13px + 0.3vw) !important;
}

h1, h2{
  font-size: calc(15px + 0.5vw) !important;
}
