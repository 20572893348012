/* Base font size for body text */
body {
  font-size: calc(14px + 0.5vw);
  line-height: 1.5;
}

h1 {
  font-size: calc(30px + 1vw);
}

h2 {
  font-size: calc(28px + 0.8vw);
}

h3 {
  font-size: calc(26px + 0.6vw);
}

h4 {
  font-size: calc(24px + 0.5vw);
}

h5 {
  font-size: calc(22px + 0.4vw);
}

h6 {
  font-size: calc(20px + 0.3vw);
}

.small-text {
  font-size: calc(18px + 0.2vw);
}

/* Responsive adjustments for very small screens */
@media (max-width: 600px) {
  body {
    font-size: calc(14px + 1vw);
  }

  h1 {
    font-size: calc(28px + 1.2vw);
  }

  h2 {
    font-size: calc(26px + 1vw);
  }

  h3 {
    font-size: calc(24px + 0.8vw);
  }

  h4 {
    font-size: calc(22px + 0.6vw);
  }

  h5 {
    font-size: calc(20px + 0.5vw);
  }

  h6 {
    font-size: calc(18px + 0.4vw);
  }

  .small-text {
    font-size: calc(16px + 0.3vw);
  }
}

/* Responsive adjustments for large screens */
@media (min-width: 1025px) {
  body {
    font-size: 18px;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 34px;
  }

  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 26px;
  }

  h5 {
    font-size: 24px;
  }

  h6 {
    font-size: 22px;
  }

  .small-text {
    font-size: 16px;
  }
}
