/* menu link */
.nav-link {
  color: black;
  /* font-weight: 600; */
}

.nav-link:hover {
  color: goldenrod;
}

/* .dropdown-item {
  font-weight: 600;
} */

.dropdown-item:hover {
  color: goldenrod;
  background-color: white;
}
/* menu link */

/* CSS for hamburger menu button */
.hamburger-menu {
  display: none;
}

/* Media query for mobile phone screen */
@media (max-width: 768px) {
  .hamburger-menu {
    display: block; /* Show the hamburger menu button for mobile view */
  }

  /* Hide the navbar when the hamburger menu button is clicked */
  .hide-navbar {
    display: none;
  }

  /* Show the navbar when the hamburger menu button is clicked */
  .show-navbar {
    display: block;
  }
}


/* dropdown positioning */
.navItemsDropdown{
  border-radius: 0%;
  border: .5px solid #000;
}

.dropItem{
  font-size: calc(.6em + .4vw);
}
/* dropdown positioning */